import React, { useState } from 'react';

export default function useForm() {
    const [feedback, setFeedback] = useState("accepted");
    const [persons, setPersons] = useState(0);
    const [selectedMeals, setSelectedMeals] = useState([]); // Array zum Speichern der Eingabewerte
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showEmailErrorMessage, setShowEmailErrorMessage] = useState(false);
    const gsheetGETRequest = process.env.REACT_APP_GOOGLE_SHEET_GET_REQUEST;
    const gsheetPOSTRequest = process.env.REACT_APP_GOOGLE_SHEET_POST_REQUEST;

    const handleSelectedMealsChange = (event, index) => {
        const newSelectedMeals = [...selectedMeals];
        newSelectedMeals[index] = event.target.value;
        setSelectedMeals(newSelectedMeals);
    };


    const onFeedbackChange = e => {
        setFeedback(e.target.value)
    };

    const onPersonsChange = e => {
        const newPersons = parseInt(e.target.value);
        setPersons(newPersons);
        setSelectedMeals(new Array(newPersons).fill(""));
    }

    const handleFocus = (event) => {
        const name = event.target.getAttribute('name');
        const type = event.target.tagName;
        document.querySelector(`#feedback-form ${type}[name="${name}"]`).classList.remove("error");
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (event.target[2].value === '' || !emailPattern.test(event.target[3].value) || persons === 0) {
            console.log('test');
            if (event.target[2].value === '') {
                document.querySelector('#feedback-form input[name="name"]').classList.add("error");
            }
            if (!emailPattern.test(event.target[3].value)) {
                document.querySelector('#feedback-form input[name="email"]').classList.add("error");
            }
            if (persons === 0) {
                document.querySelector('#feedback-form select[name="persons"]').classList.add("error");
            }

        } else {
            document.getElementById('feedback-loader').style.display = 'inline-block'
            const formData = new FormData();
            formData.append('Feedback', feedback);
            formData.append('Name', event.target[2].value);
            formData.append('Email', event.target[3].value);
            formData.append('Address', event.target[4].value);
            formData.append('Zip', event.target[5].value);
            formData.append('City', event.target[6].value);
            formData.append('Persons', persons);
            formData.append('Meals', selectedMeals.join(', '));
            formData.append('Message', Array.from(event.target.elements).find(
                element => element.tagName === 'TEXTAREA'
            ).value)

            fetch(`${gsheetGETRequest}${event.target[3].value}`, {
                method: "GET"
            }).then((response) => {
                return response.json();
            }).then((data) => {
                if (data.message === 'Email not registered') {
                    fetch(gsheetPOSTRequest, {
                        method: "POST",
                        body: formData
                    }).then((response) => {
                        if (response.ok) {
                            setShowEmailErrorMessage(false);
                            const form = document.getElementById('feedback-form');
                            var formHeight = form.offsetHeight;
                            form.style.height = formHeight + 'px';
                            setTimeout(function () {
                                form.style.height = '0px';
                                form.style.overflow = 'hidden';
                                form.style.margin = '0px auto';
                                document.getElementById('successMessageFeedback').classList.add('active');
                            }.bind(form), 1);

                            setShowSuccessMessage(true);
                            const targetElement = document.querySelector('#feedback');
                            targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
                        }
                    })
                } else {
                    document.getElementById('feedback-loader').style.display = 'none'
                    setShowEmailErrorMessage(true);
                }
            });
        }
    }

    return {
        feedback,
        persons,
        selectedMeals,
        showSuccessMessage,
        showEmailErrorMessage,
        handleSelectedMealsChange,
        onFeedbackChange,
        onPersonsChange,
        handleFormSubmit,
        handleFocus
    }
}