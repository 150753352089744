import "./styles.css";
import data from '../../content/text/timeline';
import TimelineActivity from "./TimelineActivity";


export default function Timeline() {
    return (
        <>
            <div id="tagesablauf" className="section-header orange" >
                <h2>Ablaufplan</h2>
            </div >
            <div className="timeline-container">
                <ul className="timeline-activities">
                    {Object.keys(data).map((activityKey) => (
                        <TimelineActivity
                            key={data[activityKey].Headline}
                            icon={data[activityKey].Icon}
                            headline={data[activityKey].Headline}
                        />
                    ))}
                </ul>
            </div>
        </>
    )
}