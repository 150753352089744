import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons';

export default function Person({ name, title, phone, description, image, type, person }) {

    return (
        <>
            {
                type === 'list' ? (
                    <li className="person">
                        <img className="person-image" src={require(`../../content/images/${image}.jpeg`)}></img>
                        <p className="person-name">{name}</p>
                        <p className="person-title">{title}</p>
                        <p>
                            <FontAwesomeIcon className="phone-icon" icon={faPhone} />
                            <a className="person-phone" href={`tel:${phone}`}>{phone}</a>
                        </p>
                        <p className="person-quote" >{description}</p>
                        <p className="person-quote-name">{person}</p>
                    </li>
                ) : (
                    <div>
                        <img className="person-image" src={require(`../../content/images/${image}.jpeg`)}></img>
                        <p className="person-name">{name}</p>
                        <p className="person-quote">{description}</p>
                        <p className="person-quote-name">{person}</p>
                    </div>
                )
            }

        </>
    )
}