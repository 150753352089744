import "./styles.css";
import data from '../../content/text/accommodations';
import Location from "../Location";

export default function Accommondation() {
    return (
        <>
            <div id="unterkuenfte" className="section-header orange">
                <h2>Unterkünfte</h2>
                <p>Sucht ihr nach einer Unterkunft in der Nähe? Hier findet ihr mögliche Unterkünfte.</p>
            </div>
            <div className="accomondation-container">
                <ul className="accomondation-list">
                    {Object.keys(data).map((accommodationKey) => (
                        <Location
                        key={data[accommodationKey].Name}
                        name={data[accommodationKey].Name}
                        description={data[accommodationKey].Description}
                        address={data[accommodationKey].Address}
                        locationUrl={data[accommodationKey].LocationUrl}
                        websiteUrl={data[accommodationKey].WebsiteUrl}
                        image={data[accommodationKey].Image}
                    />
                    ))}
                </ul>
            </div>
        </>
    )
}