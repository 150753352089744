import "./styles.css"
import { useLogin } from "../../context/LoginContext"
import useImageChange from "./useImageChange";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons';


export default function ImageWall() {
  const { loggedIn } = useLogin();
  const { currentImageIndex, images } = useImageChange();

  return (
    <>
      {
        loggedIn ? (
          <div className="image-slider-container">
            <div id="scrollDiv" className="image-content">
              <h1>Melli & Schorschi heiraten!</h1>
              <div className="horizontal-separator-white">
                <div className="header-icons">
                  <FontAwesomeIcon className="heart-icon" icon={faHeart} />{' '}
                  <FontAwesomeIcon className="heart-icon  large-heart" icon={faHeart} />{' '}
                  <FontAwesomeIcon className="heart-icon" icon={faHeart} />
                </div>
              </div>
              <h2>Am 05.07.2025 ist es endlich soweit!</h2>
            </div>
            <img
              src={images[currentImageIndex]}
              alt="Bild"
              className="slider-background-image"
            />
          </div>
        ) : null
      }
    </>
  )
}