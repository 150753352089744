import React, { useState, useEffect } from 'react';
import { useLogin} from '../../context/LoginContext';

export default function useLoginBox() {
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);
    const { updateLogin} = useLogin();

    const handleLogin = () => {
        const loginPassword = process.env.REACT_APP_LOGIN_PASSWORD;
        if (password === loginPassword) {
            updateLogin(true);
            localStorage.setItem('isLoggedIn', 'true'); // Speichern des Login-Status im lokalen Speicher
            localStorage.setItem('loginTime', new Date().getTime().toString()); // Speichern der aktuellen Zeit
        } else {
            setErrorMessage(true);
        }
    };

    return {
        password,
        errorMessage,
        handleLogin,
        setPassword
    }
}