import React, { useState } from 'react';

export default function useMenu() {
    const [persons, setPersons] = useState(0);
    const [selectedStarters, setSelectedStarters] = useState([]);
    const [selectedMains, setSelectedMains] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showEmailErrorMessage, setShowEmailErrorMessage] = useState(false);
    const gsheetGETRequest = process.env.REACT_APP_GOOGLE_SHEET_MENU_GET_REQUEST;
    const gsheetPOSTRequest = process.env.REACT_APP_GOOGLE_SHEET_MENU_POST_REQUEST;

    const handleFocus = (event) => {
        const name = event.target.getAttribute('name');
        const type = event.target.tagName;
        document.querySelector(`#menu-form ${type}[name="${name}"]`).classList.remove("error");
    }

    const handleSelectedStartersChange = (event, index) => {
        const newSelectedStarters = [...selectedStarters];
        newSelectedStarters[index] = event.target.value;
        setSelectedStarters(newSelectedStarters);
    };

    const handleSelectedMainsChange = (event, index) => {
        const newSelectedMains = [...selectedMains];
        newSelectedMains[index] = event.target.value;
        setSelectedMains(newSelectedMains);
    };

    const onPersonsChange = e => {
        const newPersons = parseInt(e.target.value);
        setPersons(newPersons);
        setSelectedStarters(new Array(newPersons).fill(""));
        setSelectedMains(new Array(newPersons).fill(""));
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (event.target[0].value === '' || !emailPattern.test(event.target[1].value) || persons === 0) {
            if (event.target[0].value === '') {
                document.querySelector('#menu-form input[name="name"]').classList.add("error");
            }
            if (!emailPattern.test(event.target[1].value)) {
                document.querySelector('#menu-form input[name="email"]').classList.add("error");
            }
            if (persons === 0) {
                document.querySelector('#menu-form select[name="persons"]').classList.add("error");
            }

        } else {
            document.getElementById('menu-loader').style.display = 'inline-block'
            const formData = new FormData();
            formData.append('Name', event.target[0].value);
            formData.append('Email', event.target[1].value);
            formData.append('Persons', persons);
            formData.append('Starters', selectedStarters.join(', '));
            formData.append('Mains', selectedMains.join(', '));
            formData.append('Message', Array.from(event.target.elements).find(
                element => element.tagName === 'TEXTAREA'
            ).value)

            fetch(`${gsheetGETRequest}${event.target[1].value}`, {
                method: "GET"
            }).then((response) => {
                return response.json();
            }).then((data) => {
                if (data.message === 'Email not registered') {
                    fetch(gsheetPOSTRequest, {
                        method: "POST",
                        body: formData
                    }).then((response) => {
                        if (response.ok) {
                            setShowEmailErrorMessage(false);
                            const form = document.getElementById('menu-form');
                            var formHeight = form.offsetHeight;
                            form.style.height = formHeight + 'px';
                            setTimeout(function () {
                                form.style.height = '0px';
                                form.style.overflow = 'hidden';
                                form.style.margin = '0px auto';
                                document.getElementById('successMessageMenu').classList.add('active');
                            }.bind(form), 1);


                            setShowSuccessMessage(true);
                            const targetElement = document.querySelector('#menu');
                            targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
                        }
                    })
                } else {
                    document.getElementById('menu-loader').style.display = 'none'
                    setShowEmailErrorMessage(true);
                }
            });
        }
    }

    window.onload = function () {
        const form = document.getElementById('menu');
        const overlayDiv = document.getElementById('overlayDiv');

        function adjustOverlaySize() {
            overlayDiv.style.height = `${form.clientHeight}px`;
        }

        adjustOverlaySize();

        window.addEventListener('resize', adjustOverlaySize);
    };

    return {
        persons,
        selectedStarters,
        selectedMains,
        showEmailErrorMessage,
        handleFocus,
        handleSelectedStartersChange,
        handleSelectedMainsChange,
        onPersonsChange,
        handleFormSubmit
    }
}