import React, { useState, useEffect, useContext } from 'react';

const LoginContext = React.createContext();

export const LoginProvider = ({ children }) => {
    // Standardwert auf 'light' setzen
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        const loginTime = localStorage.getItem('loginTime');
        const expirationTime = 30 * 24 * 60 * 60 * 1000;

        if (isLoggedIn === 'true' && loginTime) {
            const currentTime = new Date().getTime();
            if (currentTime - parseInt(loginTime, 10) < expirationTime) {
                setLoggedIn(true);
            } else {
                // Ablaufzeit überschritten, Benutzer ausloggen
                setLoggedIn(false);
            }
        }
    }, []);

    // Funktion, um den Theme-Wert zu aktualisieren und im localStorage zu speichern
    const updateLogin = (newLoggedIn) => {
        setLoggedIn(newLoggedIn);
    };

    return (
        <LoginContext.Provider value={{ loggedIn, updateLogin }}>
            {children}
        </LoginContext.Provider>
    );
};

export const useLogin = () => useContext(LoginContext);