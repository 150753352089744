import "./styles.css";
import useCountdown from "./useCountdown"

export default function Countdown() {
    const { timeLeft, formatTime } = useCountdown();
    return (
        <>
            <div id="countdown" className="section-header orange countdown">
                <h2>Countdown</h2>
            </div>
            <div className="countdown-container">
                <ul className="countdown">
                    <li className="counter">
                        <span className="counter-number">{formatTime(timeLeft.days)}</span>
                        <span className="counter-unit">Tage</span>
                    </li>
                    <li className="counter">
                        <span className="counter-number">{formatTime(timeLeft.hours)}</span>
                        <span className="counter-unit">Stunden</span>
                    </li>
                    <li className="counter">
                        <span className="counter-number">{formatTime(timeLeft.minutes)}</span>
                        <span className="counter-unit">Minuten</span>
                    </li>
                    <li className="counter">
                        <span className="counter-number">{formatTime(timeLeft.seconds)}</span>
                        <span className="counter-unit">Sekunden</span>
                    </li>
                </ul>
            </div>
        </>
    )
}