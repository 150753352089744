import "./app/content/css/styles.css";
import Login from "./app/components/Login";
import { LoginProvider } from "./app/context/LoginContext";
import WeddingComponents from "./app/components/WeddingComponents";


export default function App() {

  return (
    <LoginProvider>
      <Login />
      <WeddingComponents />
    </LoginProvider>
  );
}
