const data = {
    A: 'Ablaufplan findet ihr unter Tagesablauf ',
    B: 'Brautentführung möchten wir auf unserer Hochzeit nicht haben',
    C: 'Cocktails/Longdrinks bieten wir nicht, dafür Wein, Bier und andere Erfrischungen',
    D: 'Dresscode: Abendgarderobe',
    E: 'Ende ist um 1 Uhr',
    F: 'Freie Trauung beginnt um 13 Uhr',
    G: 'Gästetransport steht keiner zur Verfügung',
    H: 'Haushalt ist komplett, deshalb würden wir uns über Geldgeschenke freuen',
    I: 'Intoleranzen bitte uns bei der Menüauswahl mitteilen',
    J: 'Juni ist der Start in unser Eheleben',
    K: 'Kinder sind wundervoll, wir möchten jedoch für unsere Hochzeit eine erwachsene Atmosphäre schaffen und bitten daher darum, dass keine Kinder anwesend sind. Vielen Dank für euer Verständnis.',
    L: 'Liveband begleitet uns die ganze Feier ',
    M: 'Menü bitte ab Oktober über die Homepage auswählen',
    N: 'Niemand hat Zusatzkosten auf unserer Hochzeit, wir sorgen für euer Wohl',
    O: 'Organisatorisches rund um die Gestaltung der Feier könnt ihr gerne mit unseren Trauzeugen besprechen.',
    P: 'Parkplätze sind direkt an der Location vorhanden',
    Q: 'Qualifiziert haben sich als Trauzeugen Miri & Flo',
    R: 'Rückmeldung bitte bis spätestens 31.03.2025', 
    S: 'Sitzordnung erfahrt ihr vor Ort',
    T: 'Torte gibt es bei uns zu später Stunde',
    U: 'Unterkünfte in der Nähe findet ihr auf unserer Homepage',
    V: 'Videos und Fotos könnt ihr gerne nach der Hochzeit auf unser Dropbox/Link hochladen',
    W: 'Weiß ist an diesem Tag der Braut vorenthalten',
    X: 'Xtrem gute Laune ist Pflicht ',
    Y: 'Young and old ist bei uns vertreten',
    Z: 'Ziel der Hochzeit: einen unvergesslichen Tag  mit euch'
}

export default data;
