import "./styles.css";

export default function Footer() {

    return (
        <footer className="footer">
            <div className="footer-container">
                <p className="footer-text">Wir freuen uns auf diesen besonderen Tag mit euch!</p>
                <p className="footer-text-small">Page was made with ♡ by Miri</p>
            </div>
        </footer>
    )
}