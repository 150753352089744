import "./styles.css";

export default function FixImage() {

    return (
        <>
            <div className="fix-image-container">
                <p className="quote-large">
                    Liebe ist das einzige, was sich verdoppelt, wenn man es teilt.
                </p>
                <p className="author">
                    - Albert Schweitzer -
                </p>
            </div>
        </>
    )
}