import "./styles.css";
import useLoginBox from "./useLoginBox";
import backgroundImage from '../../content/images/wedding-table.jpg';
import { useLogin } from '../../context/LoginContext'

export default function Login() {
    const { password, errorMessage, handleLogin, setPassword } = useLoginBox();
    const { loggedIn } = useLogin();

    return (
        <>
            {
                !loggedIn ? (
                    <div className="login-container">
                        <img id="login-background" src={backgroundImage} alt="Hintergrundbild" className="background-image" />
                        <div id="login-box" className="login-box">
                            <div>
                                <h2>Willkommen!</h2>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="password"
                                        placeholder="Passwort eingeben"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                {
                                    errorMessage ? (
                                        <b>Falsches Passwort!</b>
                                    ) : null
                                }
                                <div className="form-group">
                                    <button className="form-control" onClick={handleLogin}>Los geht`s</button>
                                </div>
                            </div>

                        </div>
                    </div>
                ) : null
            }
        </>
    );
}