import "./styles.css";
import data from '../../content/text/witnesses';
import Person from "../Person";

export default function Witnesses() {
    return (
        <>
            <div id="organisatoren" className="section-header orange">
                <h2>Unsere Trauzeugen</h2>
                <p>Bitte wendet euch an unsere Trauzeugen, wenn ihr "vertrauliche" Fragen vor der Hochzeit habt :-)</p>
            </div>
            <div className="witnesses-container">
                <ul className="witnesses">
                    {Object.keys(data).map((witnessKey) => (
                        <Person
                            key={data[witnessKey].Name}
                            name={data[witnessKey].Name}
                            title={data[witnessKey].Title}
                            phone={data[witnessKey].Phone}
                            description={data[witnessKey].Description}
                            image={data[witnessKey].Image}
                            type="list"
                            person={data[witnessKey].Person}
                        />
                    ))}
                </ul>
            </div>
        </>
    )
}