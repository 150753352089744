import useForm from "./useForm";
import "../styles.css"

export default function FeedbackForm() {
    const { feedback, persons, showEmailErrorMessage, onFeedbackChange, onPersonsChange, handleFormSubmit, handleFocus } = useForm();


    return (
        <div className="feedback-content">
            <div id="successMessageFeedback" className="success-message">
                <p>Vielen Dank für deine Rückmeldung!</p>
            </div>
            <form id="feedback-form" className="feedback-form" onSubmit={handleFormSubmit} name="feedbackForm" method="POST">
                <div className="radio-container">
                    <div className="radio">
                        <label className="custom-radio-btn">
                            <span className="radio-label">Hiermit sage ich zu</span>
                            <input type="radio" value="accepted" checked={feedback === "accepted"} onChange={onFeedbackChange} />
                            <span className="radio-checkmark"></span>
                        </label>
                    </div>
                    <div className="radio">
                        <label className="custom-radio-btn">
                            <span className="radio-label">Ich kann leider nicht kommen</span>
                            <input type="radio" value="cancelled" checked={feedback === "cancelled"} onChange={onFeedbackChange} />
                            <span className="radio-checkmark"></span>
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <input className="form-control" type="text" name="name" placeholder="Vor- und Nachname*" onFocus={handleFocus} />
                </div>
                <div className="form-group">
                    <input className="form-control" type="text" name="email" placeholder="Email*" onFocus={handleFocus} />
                </div>
                {showEmailErrorMessage && (
                    <div className="error-message">
                        Mit dieser Email wurde bereits Rückmeldung gegeben!
                    </div>
                )}
                <div className="form-group">
                    <input className="form-control" type="text" name="address" placeholder="Straße & Hausnummer (optional)" />
                </div>
                <div className="form-group-two">
                    <input className="form-control" type="text" name="zip" placeholder="PLZ (optional)" />
                    <input className="form-control" type="text" name="city" placeholder="Stadt (optional)" />
                </div>
                <div className="form-group">
                    <select className="form-control" name="persons" value={persons} onChange={onPersonsChange} onFocus={handleFocus}>
                        <option value="0">Anzahl Personen*</option>
                        <option value="1">Eine Person</option>
                        <option value="2">2 Personen</option>
                    </select>
                </div>
                <div className="form-group">
                    <textarea className="form-control" name="message" placeholder="Wie sind die Namen der geladenen Gäste? Hast du noch Anmerkungen oder Wünsche? Gibt es beim Essen etwas, das wir beachten sollten (z. B. vegetarisch oder Unverträglichkeiten)? Lass es uns wissen und hilf uns bei der Planung! (optional)"></textarea>
                </div>
                <div className="form-group">
                    <button className="form-control" type="submit">
                        <span>Rückmeldung absenden</span>
                        <div id="feedback-loader" className="loader"></div>
                    </button>
                </div>
            </form>
        </div>
    );
}