import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

export default function Location({name, description, address, locationUrl, websiteUrl, image }) {

    return (
        <li className="location">
            <a className="location-map" target="_blank" href={locationUrl}>
                <div className="location-map-image hexagon">
                    <img src={require(`../../content/images/${image}.png`)}></img>
                </div>
                <FontAwesomeIcon className="location-map-icon" icon={faMapMarkerAlt} />
            </a>
            <div className="location-details">
                <h3>{name}</h3>
                <p>{description}</p>
                <p>
                    <strong>Adresse: </strong>
                    <a className="location-infos" target="_blank" href={locationUrl}>{address}</a>
                </p>
                <p>
                    <strong>Website: </strong>
                    <a className="location-infos" target="_blank" href={websiteUrl}>{name}</a>
                </p>
            </div>
        </li>
    )
}