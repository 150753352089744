import "./styles.css";
import data from '../../content/text/wedding-location';
import Location from "../Location";

export default function WeddingLocation() {

    return (
        <>
            <div id="location" className="section-header orange">
                <h2>Location</h2>
                <p>Hier findet ihr Details zu der Hochzeits-Location sowie Anreise-Informationen.</p>
            </div>
            <div className="wedding-location-container">
                <ul className="wedding-location-list">
                    <Location
                        name={data.Name}
                        description={data.Description}
                        address={data.Address}
                        locationUrl={data.LocationUrl}
                        websiteUrl={data.WebsiteUrl}
                        image={data.Image}
                    />
                </ul>
            </div>
        </>
    )
}