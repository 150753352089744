import "./styles.css";
import Person from "../Person";
import data from '../../content/text/bridalCouple';
import heartIcon from './../../content/images/heart.png';
import engagedIcon from './../../content/images/wedding-ring.png';
import married from './../../content/images/wedding-rings-icon.png';

export default function BridalCouple() {
    return (
        <>
            < div id="brautpaar" className="section-header orange" >
                <h2>Das Hochzeitspaar</h2>
            </div >
            <div className="bridal-couple-container">
                <div className="bridal-couple-wrapper">
                    <div>
                        <img className="bridal-couple-img" src={heartIcon}></img>
                        <p className="bridal-couple-text">10.06. 2022 - Verliebt</p>
                    </div>
                    <div>
                        <img className="bridal-couple-img" src={engagedIcon}></img>
                        <p className="bridal-couple-text">28.01. 2024 - Verlobt</p>
                    </div>
                    <div>
                        <img className="bridal-couple-img" src={married}></img>
                        <p className="bridal-couple-text">10.06. 2025 - Bald Verheiratet</p>
                    </div>
                </div>

                <span className="horizontal-separator" />
                <div className="couple">
                    {Object.keys(data).map((witnessKey) => (
                        <Person
                            key={data[witnessKey].Name}
                            name={data[witnessKey].Name}
                            title=""
                            phone=""
                            description={data[witnessKey].Description}
                            image={data[witnessKey].Image}
                            type="div"
                            person={data[witnessKey].Person}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}