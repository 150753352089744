const data = {
    Postgasthof: {
        Name: 'Postgasthof Rote Wand',
        Description: 'Der Postgasthof liegt nur 3 Gehminuten von der Hochzeitslocation entfernt und bietet komfortable Übernachtungsmöglichkeiten. Die Einzelzimmer beginnen bei 75€, während die Doppelzimmer im Haupthaus zwischen 110€ und 130€ liegen. Luxuriösere Optionen sind die Deluxe Doppelzimmer im neuen Landhaus mit 150€ bis 180€. Alle Preise gelten pro Nacht und inklusive Frühstück.',
        Address: 'Geitau 15, 83735 Bayrischzell-Geitau',
        LocationUrl: 'https://www.google.com/maps/place/Postgasthof+Rote+Wand/@47.6890104,11.9589052,17z/data=!3m1!4b1!4m9!3m8!1s0x477626cbd0900001:0x1e2dd09c3139ec1a!5m2!4m1!1i2!8m2!3d47.6890068!4d11.9614801!16s%2Fg%2F1tgh6d1y?entry=ttu',
        WebsiteUrl: 'http://www.rotewand.de/',
        Image: 'accommondation_1'
    },
    Aiplspitz: {
        Name: 'Aiplspitz Gasthaus-Pension',
        Description: 'Die Pension liegt 5 Gehminuten von der Hochzeitslocation entfernt und bietet eine gemütliche Unterkunft für euren Aufenthalt. Die Einzelzimmer sind für 59€ pro Nacht verfügbar, während die Doppelzimmer je nach Ausstattung und Lage zwischen 88€ und 114€ kosten. Frühstück ist inklusive.',
        Address: 'Geitau 20, 83735 Bayrischzell-Geitau',
        LocationUrl: 'https://www.google.com/maps/place/Aiplspitz+Gasthaus-Pension/@47.689409,11.9609987,16z/data=!4m9!3m8!1s0x477626c9651406cf:0xd4f94ab352efec60!5m2!4m1!1i2!8m2!3d47.6881199!4d11.9609236!16s%2Fg%2F1tdkfgmx?entry=ttu',
        WebsiteUrl: 'https://www.gasthaus-aiplspitz.com/',
        Image: 'accommondation_2'
    },
    Gödenbauer: {
        Name: 'Gödenbauer Ferienwohnung (Familie Rosenberger)',
        Description: 'Die Ferienwohnung liegt 5 Gehminuten von der Hochzeitslocation entfernt und bietet eine warme Atmosphäre für euren Aufenthalt. Genießt den Komfort der Doppelzimmer, die bereits ab 80€ inklusive Frühstück verfügbar sind.',
        Address: 'Geitau 36, 83735 Bayrischzell-Geitau',
        LocationUrl: 'https://www.google.com/maps/place/Familie+Rosenberger/@47.6904336,11.9591951,17z/data=!3m1!4b1!4m6!3m5!1s0x477626cbbb287691:0x47f6a6ac67c1ef64!8m2!3d47.69043!4d11.96177!16s%2Fg%2F11ckvknb69?entry=ttu',
        WebsiteUrl: 'https://www.goedenbauer.de/',
        Image: 'accommondation_3'
    }
}

export default data;