import React, { useState, useEffect } from 'react';
import image1 from '../../content/images/Image_Wall_1.jpeg';
import image1Laptop from '../../content/images/Image_Wall_1_Laptop.jpeg';
import image2 from '../../content/images/Image_Wall_2.jpeg';
import image3 from '../../content/images/Image_Wall_3.jpeg';
import image3Laptop from '../../content/images/Image_Wall_3_Laptop.jpeg';
import image4 from '../../content/images/Image_Wall_4.jpeg';

export default function useImageChange() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const imageUrls = [
        image1Laptop,
        image3Laptop,
        image4
    ];
    const imageUrlsMobile = [
        image1,
        image2,
        image3,
        image4
    ];

    const [images, setImages] = useState([]);


    useEffect(() => {
        const newImages = window.innerWidth <= 768 ? imageUrlsMobile : imageUrls;
        setImages(newImages);
    }, []);

    useEffect(() => {
        const newImageUrls = window.innerWidth <= 768 ? imageUrlsMobile : imageUrls;
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % newImageUrls.length);
        }, 7000);
        return () => clearInterval(interval);
    }, []);

    document.addEventListener("scroll", function () {
        var scrollDiv = document.getElementById("scrollDiv");
        if (window.scrollY > 0) {
            scrollDiv.classList.add("hidden");
        } else {
            scrollDiv.classList.remove("hidden");
        }
    });



    return {
        currentImageIndex,
        images
    }
}
