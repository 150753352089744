import "./styles.css";
import React from 'react';
import data from '../../content/text/wedding-abc';


export default function WeddingABC() {
    return (
        <>
            < div id="hochzeitsAbc" className="section-header orange" >
                <h2>Unser Hochzeits-ABC</h2>
            </div >
            <div className="abc-container">
                <ul className="abc-content">
                    {Object.entries(data).map(([key, value]) => (
                        <li className="abc-item" key={key}>
                            <div className='abc-letter'>
                                {key}
                            </div>
                            <div className='abc-text'>{value}</div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}