import "../styles.css";
import image from '../../../content/images/cheers.png'


export default function TimelineActivity({ icon, headline }) {
    return (
        <>
            <li className="timeline-activity">
                <div className="activity-icon-wrapper">
                    <div className="activity-icon-container">
                        <img className="activity-icon" src={require(`../../../content/images/${icon}.png`)}></img>
                    </div>
                </div>
                <div className="activity-content">
                    <h3 className="activity-headline">
                        {headline}
                    </h3>
                </div>
            </li>
        </>
    )
}