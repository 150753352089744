import "./styles.css";
import useMenu from "./useMenu";

export default function Menu() {
    const { persons, selectedStarters, selectedMains, showEmailErrorMessage, handleFocus, handleSelectedStartersChange, handleSelectedMainsChange, onPersonsChange, handleFormSubmit } = useMenu();

    return (
        <>
            <div id="menu" className="feedback-container">
                <div className="section-header orange">
                    <h2>Menü Auswahl</h2>
                    <p>Erst ab Oktober möglich!</p>
                </div>
                <div id="successMessageMenu" className="success-message">
                    <p>Vielen Dank für deine Rückmeldung!</p>
                </div>
                <form id="menu-form" className="menu-form" onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <input className="form-control" type="text" name="name" placeholder="Vor- und Nachname*" onFocus={handleFocus} />
                    </div>
                    <div className="form-group">
                        <input className="form-control" type="text" name="email" placeholder="Email*" onFocus={handleFocus} />
                    </div>
                    {showEmailErrorMessage && (
                        <div className="error-message">
                            Mit dieser Email wurde bereits Rückmeldung bzgl. dem Menü gegeben!
                        </div>
                    )}
                    <div className="form-group">
                        <select className="form-control menu" name="persons" value={persons} onChange={onPersonsChange} onFocus={handleFocus}>
                            <option value="0">Anzahl Personen*</option>
                            <option value="1">Eine Person</option>
                            <option value="2">2 Personen</option>
                            <option value="3">3 Personen</option>
                            <option value="4">4 Personen</option>
                            <option value="5">5 Personen</option>
                            <option value="6">6 Personen</option>
                        </select>
                    </div>
                    {
                        !(selectedStarters.length === 0) ? (
                            <div>
                                <b>Vorspeise</b>
                            </div>
                        ) : null
                    }
                    <div className="form-group menu">
                        {
                            selectedStarters.map((option, index) => (
                                <select
                                    className="form-control menu"
                                    key={index}
                                    value={option || ""}
                                    onChange={(e) => handleSelectedStartersChange(e, index)}
                                >
                                    <option value="">Bitte wählen...</option>
                                    <option value="rinderfilet">Rinderfilet</option>
                                    <option value="vegetarisch">Vegetarisch</option>
                                    <option value="vegan">Vegan</option>
                                </select>
                            ))
                        }
                    </div>
                    {
                        !(selectedStarters.length === 0) ? (
                            <div>
                                <b>Hauptspeise</b>
                            </div>
                        ) : null
                    }
                    <div className="form-group menu">
                        {
                            selectedMains.map((option, index) => (
                                <select
                                    className="form-control menu"
                                    key={index}
                                    value={option || ""}
                                    onChange={(e) => handleSelectedMainsChange(e, index)}
                                >
                                    <option value="">Bitte wählen...</option>
                                    <option value="rinderfilet">Rinderfilet</option>
                                    <option value="vegetarisch">Vegetarisch</option>
                                    <option value="vegan">Vegan</option>
                                </select>
                            ))
                        }
                    </div>
                    <div className="form-group">
                        <textarea className="form-control" name="message" placeholder="Gibt es beim Essen etwas, das wir beachten sollten (z. B. vegetarisch oder Unverträglichkeiten)? Lass es uns wissen und hilf uns bei der Planung! (optional)"></textarea>
                    </div>
                    <div className="form-group">
                        <button className="form-control" type="submit">
                            <span>Menü absenden</span>
                            <div id="menu-loader" className="loader"></div>
                        </button>
                    </div>
                </form>
                <div id="overlayDiv"></div>
            </div>
        </>
    )
}