import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons';

export default function IntroText() {
    return (
        <>
            <div className="intro-container" id="invitation">
                <h2 className="intro-text">Sommerhochzeit</h2>
                <div className="intro-horizontal-separator">
                    <div className="intro-text-icon">
                        <FontAwesomeIcon icon={faHeart} />{' '}
                    </div>
                </div>
                <div className="intro-text-text">
                    Liebe Gäste,
                    <br />
                    <br />
                    zu unserer Hochzeit am 05. Juli 2025 laden wir euch recht herzlich ins schöne Bayrischzell ein!
                    <br />
                    <br />
                    Gebt uns doch bitte bis 31.03.2025 Bescheid, ob ihr diesen Tag mit uns feiern wollt.
                    <br />
                    <br />
                    Liebe Grüße,
                    <br />
                    <br />
                    Melanie und Schorschi
                </div>
            </div>
        </>
    )
}