const data = {
    Trauung: {
        Icon: 'wedding-rings-icon',
        Headline: '13:00 Uhr - Freie Trauung'
    },
    Sektempfang: {
        Icon: 'cheers',
        Headline: '14:00 Uhr - Empfang und Gratulation'
    },
    Kaffee: {
        Icon: 'coffee_cake',
        Headline: '15:30 Uhr - Kaffee & Kuchen'
    },
    Dinner: {
        Icon: 'dinner',
        Headline: '18:00 Uhr - Dinner'
    },
    Tanz: {
        Icon: 'dance',
        Headline: '20:30 Uhr - Eröffnungstanz'
    },
    Party: {
        Icon: 'party',
        Headline: '20:45 Uhr - Party'
    },
    Torte: {
        Icon: 'cake',
        Headline: '23:00 Uhr - Hochzeitstorte'
    },
    Ende: {
        Icon: 'ende',
        Headline: '01:00 Uhr - The End'
    }
}

export default data;
