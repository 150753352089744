const data = {
    Flo: {
        Name: 'Florian',
        Title: 'Trauzeuge von Schorschi',
        Phone: '+49 1577 3821910',
        Description: '"Flo ist einer meiner ältesten Freunde aus Kindergartenzeiten. Wir haben unsere gesamte Kindheit und Jugend miteinander verbracht und schon damals haben wir uns versprochen, dass er mein Trauzeuge sein würde, wenn der Tag der Hochzeit kommt. Wir sind uns in vielem gleich – ob positiv oder „negativ“ – weshalb ich ihm als Trauzeugen voll vertraue."',
        Person: "- Schorschi",
        Image: 'Flo'
    },
    Miri: {
        Name: 'Miri',
        Title: 'Trauzeugin von Melli',
        Phone: '+49 1577 0364717',
        Description: '"Miri ist nicht nur meine Schwester sondern beste Freundin, Vertraute und Seelenverwandte. Seit unserer Kindheit haben wir unzählige Erinnerungen geteilt. Sie kennt mich besser als jeder andere und versteht meine Wünsche und Ängste. Deshalb kann ich mir niemand besseren als meine Trauzeugin vorstellen."',
        Person: "- Melli",
        Image: 'Miri'
    }
}

export default data;