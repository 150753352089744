const data = {
    Melli: {
        Name: 'Melli',
        Description: '"Als ich Melli  das erste Mal sah, lag sie vor mir auf der Behandlungsliege. Während ich sie behandelte, dachte ich zunächst nicht viel darüber nach, besonders weil sie sehr jung aussah. Doch als sie Jahre später zu mir in die Praxis kam, wurde mir bewusst, dass da mehr war. Als ich ihre Daten aufnahm und sah, dass sie mein Alter hatte wurde sie auf einmal sehr interessant für mich. Vielleicht werde ich euch auf der Hochzeit verraten, was mich damals wie heute so an ihr faszinierte, dass ich sie keinen Tag mehr missen möchte."',
        Person: "- Schorschi",
        Image: 'Melli'
    },
    Schorschi: {
        Name: 'Schorschi',
        Description: '"Vom Therapeuten und seiner Patientin zum perfekten Paar. Trotz unserer Unterschiede schlagen unsere Herzen im selben Takt. Keine Streitereien, keine Meinungsverschiedenheiten, nur unvergessliche Momente. Gemeinsam haben wir bereits so viele schöne Erinnerungen geschaffen, und ich kann es kaum erwarten, all die kommenden Abenteuer mit dir zu erleben."',
        Person: "- Melli",
        Image: 'Schorschi'
    }
}

export default data;