import "./styles.css"
import FeedbackForm from "./FeedbackForm";

export default function Feedback() {

  return (
    <>
      <div id="feedback" className="feedback-container">
        <div className="section-header orange">
          <h2>Rückmeldung</h2>
          <p>Bitte gebt uns eine Rückmeldung bis zum 31.03.2025</p>
        </div>
        <FeedbackForm />
      </div>
    </>
  )
}